import React, { Component } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export default class Loading extends Component {
  render() {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress
          color="primary"
          size={40}
        />
      </Box>
    );
  }
}
