import React, { Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

import Loading from "./Components/Loading";
import "./App.css";

const Home = lazy(() => import("./Pages/Home/Home"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Weddings = lazy(() => import("./Pages/Weddings/Weddings"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
  }

  render() {
    const theme = createTheme({
      palette: {
        primary: {
          main: "#afac99",
        },
      },
      // typography: {
      //   fontFamily: ['"Josefin Sans"'].join(","),
      // },
    });
    return (
      <ThemeProvider theme={theme}>
        <Router history={this.history}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/contact" component={Contact} />
              <Route path="/couple" component={Weddings} />
              <Route path="/" component={Home} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
